/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import "mat-form-field";
@import "mat-menu";
@import "mat-tab-group-v15";
@import "mat-dialog";
@import "mat-progress-spinner";
@import "mat-checkbox";
@layer base {
  html {
    font-family: "Ubuntu", sans-serif !important;
    color: white;
  }
}

body {
  --mdc-theme-primary: #30373a;
  --mdc-circular-progress-active-indicator-color: #999999;
}

input {
  font-size: 18px;
  color: black;
}

.inputicons {
  @apply w-full rounded-full bg-white flex flex-row items-center;
  width: 100%;
  height: 50px;
  input {
    @apply placeholder-gray-300;
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
    &:focus {
      outline: none;
    }
  }
  .prefix {
    @apply text-dark;
    position: relative;
    font-size: 20px;
    width: 22px;
    height: 22px;
    // top: 50%;
    //  bottom: 50%;
    left: 10px;
  }
  .suffix {
    @apply text-grey;
    position: relative;
    font-size: 20px;
    width: 22px;
    height: 22px;
    right: 10px;
  }
}

// button {
//   // min-width: 180px;
//   // min-height: 50px;
//   @apply py-3 w-5/12 rounded-full;
//   &.btnregister {
//     background-color: #bbbbbb;
//   }
//   &.btnlogin {
//     background-color: #c61932;
//   }
// }

html,
body {
  height: 100%;
}
body {
  @apply font-ubuntu m-0 p-0;
}

::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #81838a;
  // background: #999999;
  // background: #30373a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: block !important;
  border-radius: 30px;
  background: rgb(126, 127, 133);
  transition: all 0.5s;
}

.error-dialog .mat-mdc-dialog-container {
  // @apply  bg-[#f2f2f2];
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.cdk-overlay-pane {
  overflow-y: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.filter .mat-select-value-text {
  @apply text-mc-uniform;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  @apply stroke-mc-uniform;

  align-items: center;
}

.loginSpinner.mat-mdc-progress-spinner
  .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: white;
}

// .owl-carousel {
//   @apply relative;

//   &.owl-loaded {
//     display: flex !important;
//     align-items: center;
//     justify-content: space-between;
//   }
// }

// .owl-theme .owl-nav {
//   @apply absolute;
//   right: -125px;

//   //@apply flex m-0;
// }

// .owl-theme .owl-nav [class*='owl-'] {
//   @apply bg-transparent border-white border-2 p-1;
//   border-radius: 50%;
// }

.info {
  ul {
    @apply block list-disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  a:-webkit-any-link {
    @apply cursor-pointer underline;
    color: -webkit-link;
  }
}
