mat-checkbox.styled {
  &.mat-checkbox-checked {
    background-color: transparent;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    // border: 2px solid black;
    box-sizing: border-box;
    color: white !important;
    .mat-checkbox-layout {
      .mat-checkbox-label {
        color: white !important;
      }
    }
  }

  .mat-checkbox-background {
    background-color: transparent !important;
  }

  .mat-checkbox-inner-container {
    height: 16px !important;
    //top: -1px;
    border: solid 1px white;
  }

  .mat-checkbox-inner-container-no-side-margin {
    width: 26px !important;
    border-radius: 26px;
    border: 1px solid white;
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Checkbox
/* ----------------------------------------------------------------------------------------------------- */
.mat-checkbox.styledmobilitycompany {
  width: 100%;
  display: inline-flex;
  min-height: 40px;
  /* Allow multiline text */
  .mat-checkbox-layout {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: normal;

    .mat-checkbox-inner-container {
      background-color: transparent;
      display: inline-flex;
      align-items: center;
      margin: 0 8px 0 0;
      width: 24px;
      min-height: 24px;
      height: inherit !important;

      /* Add a zero-width space character to trick the container */
      /* into being the same height as a single line of the label */
      &:after {
        content: "\200b";
      }

      .mat-checkbox-background {
        width: 24px;
        height: 24px;

        svg {
          border: solid 1px gray;
          border-radius: 50%;
          padding: 2px;
        }
      }
    }

    .mat-checkbox-label {
      line-height: inherit;
      // color: white;
      color: #30373a;
    }
    .mat-checkbox-ripple {
      background: transparent !important;
    }
    .mat-ripple-element {
      background: transparent !important;
    }
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-checkbox-ripple,
  .mat-ripple-element {
    background: transparent !important;
  }

  .mat-checkbox-background {
    background-color: transparent !important;
  }
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #30373a;
  --mdc-checkbox-selected-hover-icon-color: rgba(255, 255, 255, 0.6);
  --mdc-checkbox-selected-icon-color: rgba(255, 255, 255, 0.6);
  --mdc-checkbox-selected-pressed-icon-color: rgb(255, 255, 255, 0.4);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(100, 100, 100, 0.54);
}

mat-checkbox.styledmobilitycompanyv15.mat-mdc-checkbox {
  &.withBgWhite {
    --mdc-checkbox-selected-checkmark-color: #000;
    --mdc-checkbox-selected-focus-icon-color: rgba(255, 255, 255, 0.6);
    --mdc-checkbox-selected-hover-icon-color: rgba(255, 255, 255, 0.6);
    --mdc-checkbox-selected-icon-color: rgba(255, 255, 255, 0.6);
    --mdc-checkbox-selected-pressed-icon-color: rgb(255, 255, 255, 0.4);
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(100, 100, 100, 0.54);

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      border-color: gray;
    }
  }

  .mdc-form-field {
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    //background-color: rgb(255,0,0,0.4);
    //border: white 1px solid;
    label {
      @apply text-[#30373a] font-bold font-ubuntu;
      // text-white
    }
  }

  .mdc-checkbox {
    //@apply m-0 p-0;
    .mdc-checkbox__background {
      width: 24px;
      height: 24px;
      border: solid 1px gray;
      border-radius: 50%;
      padding: 2px;
      top: 8px;
      left: 8px;

      svg {
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        top: 3px;
        left: 3px;
      }
    }
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
        [data-indeterminate="true"]
      )
      ~ .mdc-checkbox__background {
      border-color: #000;
    }
  }

  .mat-mdc-checkbox-checked {
    .mdc-form-field {
      background-color: #30373a !important;
      label {
        @apply text-white #{!important};

        // text-white
      }
    }
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    // background-color: transparent;
    background-color: white;
    // border:solid 1px gray;
    border: solid 1px #30373a;
  }

  &.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background-color: #30373a;
  }
}

mat-checkbox.mat-mdc-checkbox-checked {
  .mdc-form-field {
    background-color: #30373a !important;
    label {
      color: white !important;
      // @apply text-white;

      // text-white
    }
  }
}
