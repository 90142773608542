// Mat Tab Group
mat-tab-group.styledmobilitycompanyv15 {
  &.forDialog {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab {
              &.mdc-tab--active {
                @apply text-black;
                .mdc-tab__content {
                  .mdc-tab__text-label {
                    @apply text-black;
                  }
                }

                .mdc-tab-indicator {
                  .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                    @apply border-black;
                  }
                }
              }

              .mdc-tab__content {
                .mdc-tab__text-label {
                  @apply text-gray-400;
                }
              }

              .mdc-tab-indicator {
                .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                  @apply border-gray-400;
                }
              }
            }

            .mat-icon {
            }
          }
        }
      }
    }
  }

  &.bgGray {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab {
              &.mdc-tab--active {
                @apply text-black;
                .mdc-tab__content {
                  .mdc-tab__text-label {
                    @apply text-black;
                  }
                }

                .mdc-tab-indicator {
                  .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                    @apply border-black;
                  }
                }
              }

              .mdc-tab__content {
                .mdc-tab__text-label {
                  @apply text-gray-400;
                }
              }

              .mdc-tab-indicator {
                .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                  @apply border-gray-400;
                }
              }
            }

            .mat-icon {
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          @apply w-full;

          .mdc-tab {
            &.mdc-tab--active {
              @apply text-white;
              .mdc-tab__content {
                .mdc-tab__text-label {
                  @apply text-white font-ubuntu;
                }
              }

              .mdc-tab-indicator {
                .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                  @apply border-white  border-b-2 border-solid;
                }
              }
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                @apply text-gray-600 font-ubuntu font-semibold;
              }
            }

            .mdc-tab-indicator {
              .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                @apply border-gray-600  border-b-2 border-solid;
                opacity: 1 !important;
              }
            }
          }

          .mat-icon {
            font-size: 20px;
            height: 20px !important;
            width: 20px !important;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  @apply w-full;
}

// /Mat Tab Group

//
.mat-mdc-tab-nav-bar.styledmobilitycompanyv15 {
  &.mat-mdc-tab-header {
    @apply w-full mb-5;
    .mat-mdc-tab-list {
      .mat-mdc-tab-links {
        a {
          &.mdc-tab.mat-mdc-tab-link {
            &.mdc-tab--active.mdc-tab-indicator--active {
              .mdc-tab__content {
                .mdc-tab__text-label {
                  @apply text-dark font-ubuntu;
                }
              }

              .mdc-tab-indicator {
                .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                  @apply border-dark  border-b-[3px] border-solid;
                }
              }
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                @apply text-grey font-ubuntu;
              }
            }

            .mdc-tab-indicator {
              .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
                @apply border-grey border-b-[3px] border-l-0 border-t-0 border-r-0 border-solid font-ubuntu;
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }

    .mat-mdc-tab-header-pagination {
      @apply border-white;
      .mat-mdc-tab-header-pagination-chevron {
        @apply border-white;
      }
    }
  }
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: white;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: #fff9;
  --mat-tab-header-active-label-text-color: white;
  --mat-tab-header-active-ripple-color: white;
  --mat-tab-header-inactive-ripple-color: white;
  --mat-tab-header-inactive-focus-label-text-color: #fff9;
  --mat-tab-header-inactive-hover-label-text-color: #fff9;
  --mat-tab-header-active-focus-label-text-color: white;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-indicator-color: white;
  --mat-tab-header-active-hover-indicator-color: white;
}

.mat-mdc-tab-nav-bar.styledmobilitycompanyv15.mat-mdc-tab-header
  .mat-mdc-tab-header-pagination,
.mat-mdc-tab-nav-bar.styledmobilitycompanyv15.mat-mdc-tab-header
  .mat-mdc-tab-header-pagination
  .mat-mdc-tab-header-pagination-chevron {
  --tw-border-opacity: 1;
  border-color: grey;
}

:root {
  --tab-indicator-color: #D9D9D9;
  --tab-indicator-width: 2px;
}
.mat-mdc-tab-nav-bar.styledmobilitycompanyv15 .mdc-tab-indicator__content--underline {
  border-bottom-color: var(--tab-indicator-color);
  border-bottom-width: var(--tab-indicator-width) !important;
}





